<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-row :gutter="10" class="top_l">
					<el-col :span="5" :xs="24">
						<el-select v-model="auditStatus" clearable placeholder="请选择任务类型">
							<el-option :key="0" label="新手 " :value="0" />
							<el-option :key="1" label="每日" :value="1" />
						</el-select>
					</el-col>
					<el-col :span="19" :xs="24">
						<el-button type="primary" @click="gameShare">搜索</el-button>
						<el-button type="primary" @click="ConfigureGearsClick">配置</el-button>
					</el-col>
				</el-row>
				<!-- tab  -->
				<el-tabs type="border-card" v-model="activeName" @tab-click="tachenClick">
					<el-tab-pane v-for="(item, index) in tabDataList" :key="index" :name="item.id" :label="item.minEcpm + '-' + item.topEcpm"></el-tab-pane>
				</el-tabs>

				<!-- table 表格 -->
				<el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }" border stripe>
					<el-table-column prop="num" label="观看视频次数"></el-table-column>
					<el-table-column prop="awards" label="提现机会（元）"></el-table-column>
					<el-table-column label="任务类型">
						<template v-slot="scope">
							<span v-if="scope.row.type == 0">新手</span>
							<span v-else>每日</span>
						</template>
					</el-table-column>
					<el-table-column prop="createTime" label="创建时间"></el-table-column>
					<el-table-column prop="updateTime" label="更新时间"></el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
				<!-- 分页 -->
				<div class="pagin">
					<el-pagination
						:small="true"
						v-model:currentPage="currentPage"
						v-model:page-size="pageSize"
						:page-sizes="[10, 20, 50, 100]"
						background
						layout="total, sizes, prev, pager, next, jumper"
						:total="+totals"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
					/>
				</div>
				<!-- 配置档位 -->
				<el-dialog v-model="dialogAdd" title="配置档位" width="60%" :fullscreen="fullscreenshow">
					<div v-if="configureShow">
						<el-form
							v-for="(numItem, index) in tabDataList"
							:key="index"
							:model="numItem"
							:rules="rules"
							:ref="`refForm${index}`"
							label-width="110px"
							class="demo-ruleForm"
						>
							<el-row :gutter="20">
								<el-col :span="7" :xs="24">
									<el-form-item label="最低ecpm" prop="minEcpm">
										<el-input-number v-model="numItem.minEcpm" :step="1" :min="0" placeholder="请输入最低ecpm" />
									</el-form-item>
								</el-col>
								<el-col :span="7" :xs="24">
									<el-form-item label="最高ecpm" prop="topEcpm">
										<el-input-number v-model="numItem.topEcpm" :step="1" :min="0" placeholder="请输入最高ecpm" />
									</el-form-item>
								</el-col>
								<el-col :span="7" :xs="24">
									<el-form-item label="用户所得比例" prop="userRate">
										<el-input-number v-model="numItem.userRate" :step="0.01" :min="0" placeholder="请输入用户所得比例" />
									</el-form-item>
								</el-col>
								<el-col :span="3" :xs="24"><el-button type="danger" icon="el-icon-delete" @click="gearsRemover(index)"></el-button></el-col>
							</el-row>
						</el-form>
						<el-row :gutter="20">
							<el-col :span="3" :offset="21" :xs="24"><el-button type="primary" icon="el-icon-plus" @click="addgearsclick" size="small">添加挡位</el-button></el-col>
						</el-row>
					</div>
					<div v-else>
						<div>
							<el-alert title="新手任务" type="success" :closable="false" style="margin: 20px 0;" />
							<el-form
								v-for="(awardsItem, index) in rewardDataList"
								:key="index"
								:model="awardsItem"
								:rules="awardsrules"
								:ref="`awardsruleForm${index}`"
								label-width="180px"
								class="demo-ruleForm"
							>
								<el-row :gutter="20">
									<el-col :span="12" :xs="24" v-if="awardsItem.type == 0">
										<el-form-item label="奖励提现机会（元）" prop="awards">
											<el-input-number v-model="awardsItem.awards" :step="1" :min="0" placeholder="请输入奖励提现机会（元）" />
										</el-form-item>
									</el-col>
									<el-col :span="3" v-if="awardsItem.type == 0" :xs="24">
										<el-button type="danger" icon="el-icon-delete" @click="awardsxinshouRemover(index)"></el-button>
									</el-col>
								</el-row>
							</el-form>
							<el-col :span="3" :offset="21" :xs="24">
								<el-button type="primary" icon="el-icon-plus" @click="addxinshourewardclick" size="small">添加挡位</el-button>
							</el-col>
						</div>
						<div>
							<el-alert title="每日任务" type="warning" :closable="false" style="margin:20px 0;" />
							<el-form
								v-for="(awardsItem, index) in rewardDataList"
								:key="index"
								:model="awardsItem"
								:rules="awardsrules"
								:ref="`awardsruleForm${index}`"
								label-width="180px"
								class="demo-ruleForm"
							>
								<el-row :gutter="20">
									<el-col :span="12" :xs="24" v-if="awardsItem.type == 1">
										<el-form-item label="奖励提现机会（元）" prop="awards">
											<el-input-number v-model="awardsItem.awards" :step="1" :min="0" placeholder="请输入奖励提现机会（元）" />
										</el-form-item>
									</el-col>
									<el-col :span="3" v-if="awardsItem.type == 1" :xs="24">
										<el-button type="danger" icon="el-icon-delete" @click="awardsmeiriRemover(index)"></el-button>
									</el-col>
								</el-row>
							</el-form>
							<el-col :span="3" :offset="21" :xs="24">
								<el-button type="primary" icon="el-icon-plus" @click="addmeirirewardclick" size="small">添加挡位</el-button>
							</el-col>
						</div>
					</div>
					<template #footer>
						<span v-if="configureShow" class="dialog-footer"><el-button type="primary" @click="submitClick">下一步</el-button></span>
						<span v-if="configureShow == false" class="dialog-footer" style="margin-right: 20px;"><el-button type="primary" @click="previousstep">上一步</el-button></span>
						<span v-if="configureShow == false" class="dialog-footer"><el-button type="primary" @click="previewConfiguration">预览</el-button></span>
					</template>
				</el-dialog>

				<!-- 预览挡位 -->
				<el-dialog v-model="dialogPreviewConfiguration" title="预览挡位" width="40%" :fullscreen="fullscreenshow">
					<div v-for="(preItem, index) in PreviewConfigurationDataList" :key="index" class="text" :class="{'active':preItem.includes('ecpm')}">{{ preItem }}</div>
					<template #footer>
						<span class="dialog-footer"><el-button type="primary" @click="creategear">创建</el-button></span>
					</template>
				</el-dialog>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'userMissionConfig',
	data() {
		return {
			fullscreenshow: false,
			emptyText: '暂无数据',
			currentPage: 1,
			pageSize: 10,
			tableData: [],
			totals: null,
			serloading: false,
			productId: '',
			auditStatus: '',
			// 挡位列表
			tabDataList: [],
			// 奖励列表
			rewardDataList: [],
			activeName: null,
			// 新增配置
			dialogAdd: false,
			configureShow: true,
			// 预览挡位
			dialogPreviewConfiguration: false,
			PreviewConfigurationDataList: [],

			rules: {
				minEcpm: [{ required: true, message: '请输入最低ecpm', trigger: 'blur' }],
				topEcpm: [{ required: true, message: '请输入最高ecpm', trigger: 'blur' }],
				userRate: [{ required: true, message: '请输入用户所得比例', trigger: 'blur' }]
			},
			awardsrules: {
				awards: [{ required: true, message: '请输入奖励提现机会（元）', trigger: 'blur' }]
			}
		};
	},
	created() {
		if (document.body.clientWidth < 768) {
			this.fullscreenshow = true;
		} else {
			this.fullscreenshow = false;
		}
		this.productId = this.$TOOL.data.get('DATA_SELECTEED');
		// 获取档位列表
		this.gearlist();
	},
	watch: {
		'$store.state.dataSelected'(newval) {
			if (this.productId != newval) {
				this.productId = newval;
				// 获取档位列表
				this.gearlist();
			}
		}
	},
	methods: {
		// tabs 切换
		tachenClick(tab) {
			// 获取任务列表
			this.taskConfig(tab.props.name);
		},
		// 获取档位列表
		gearlist() {
			this.$HTTP.post(
				'userMissionConfig/queryGears',
				{
					productId: this.productId
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tabDataList = response.data.data;
							this.activeName = response.data.data[0].id;
							// 获取任务列表
							this.taskConfig(this.activeName);
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 获取奖励列表
		rewardlist() {
			this.$HTTP.post(
				'userMissionConfig/queryAwards',
				{
					productId: this.productId
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.rewardDataList = response.data.data;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 获取任务列表
		taskConfig(e) {
			this.serloading = true;
			this.$HTTP.post(
				'userMissionConfig/query',
				{
					type: this.auditStatus,
					productId: this.productId,
					pageNumber: this.currentPage,
					pageSize: this.pageSize,
					gearsId: e
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data.records;
							this.totals = response.data.data.total;
							this.serloading = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 搜索
		gameShare() {
			this.currentPage = 1;
			// 获取档位列表
			this.gearlist();
		},
		
		// 配置挡位显示
		ConfigureGearsClick() {
			this.configureShow = true;
			this.dialogAdd = true;
			// 获取档位列表
			this.gearlist();
		},
		// 追加挡位
		addgearsclick() {
			this.tabDataList.push({ minEcpm: 0, topEcpm: 0, userRate: 0 });
		},
		// 追加挡位 新手
		addxinshourewardclick() {
			this.rewardDataList.push({ awards: 0, type: 0 });
		},
		// 追加挡位 每日
		addmeirirewardclick() {
			this.rewardDataList.push({ awards: 0, type: 1 });
		},
		// 删除
		gearsRemover(index) {
			this.tabDataList.splice(index, 1);
		},
		awardsxinshouRemover(index) {
			this.rewardDataList.splice(index, 1);
		},
		awardsmeiriRemover(index) {
			this.rewardDataList.splice(index, 1);
		},
		// 上一步
		previousstep(){
			this.configureShow = true;
		},
		// 配置挡位
		submitClick() {
			//用Promise.all进行全部form表单的验证
			Promise.all([
				//数组部分的表单，用map返回验证函数的调用
				...this.tabDataList.map((item, index) => this.validateForm(`refForm${index}`))
			]).then(res => {
				if (res) {
					// 全部表单验证通过
					// 获取奖励列表
					this.rewardlist();
					this.configureShow = false;
				}
			});
		},
		validateForm(refs) {
			//获取到form表单的dom，如果是对象直接拿到，如果是数组，就拿第一个
			//elementui对循环的form包装成了数组
			let valiForm = this.$refs?.[refs].validate ? this.$refs?.[refs] : this.$refs?.[refs][0];
			return new Promise((resolve, reject) => {
				//在Promise里进行验证，如果通过就resolve()
				valiForm.validate(res => {
					if (res) resolve();
					else reject();
				});
			});
		},

		// 预览配置
		previewConfiguration() {
			//用Promise.all进行全部form表单的验证
			Promise.all([
				//数组部分的表单，用map返回验证函数的调用
				...this.rewardDataList.map((item, index) => this.validateForm(`awardsruleForm${index}`))
			]).then(res => {
				if (res) {
					// 全部表单验证通过
					this.$HTTP.post(
						'userMissionConfig/preview',
						{
							productId: this.productId,
							gears: this.tabDataList,
							awards: this.rewardDataList
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.PreviewConfigurationDataList = response.data.data;
									this.$message.success(response.data.msg);
									this.dialogPreviewConfiguration = true;
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				}
			});
		},
		// 创建挡位
		creategear() {
			this.$HTTP.post(
				'userMissionConfig/createMissionConfig',
				{
					productId: this.productId,
					gears: this.tabDataList,
					awards: this.rewardDataList
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.$message.success(response.data.msg);
							this.dialogPreviewConfiguration = false;
							this.dialogAdd = false;
							// 获取档位列表
							this.gearlist();
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 分页
		handleSizeChange(val) {
			this.currentPage = 1;
			this.pageSize = val;
			// 获取任务列表
			this.taskConfig();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			// 获取任务列表
			this.taskConfig();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.el-input-number {
	width: 100%;
}
.text {
	margin-top: 20px;
}
.active{
	font-weight: bold;
	color: red;
	font-size: 16px;
	margin-top: 0;
}
</style>
